export const state = () => ({
  schemaOrg: {
    store: {}
  }
})

export const getters = {
  schemaOrg: state => state.schemaOrg
}

export const mutations = {
  SET_STORE: (state, store) => { state.schemaOrg.store = store }
}

export const actions = {
  setStore ({ commit }, store) {
    commit('SET_STORE', store)
  }
}
