export const state = () => ({
  search: {
    id: null,
    history: [],
    queries: []
  }
})

export const getters = {
  search: state => state.search
}

export const mutations = {
  SET_SEARCH: (state, search) => {
    if ('id' in search) {
      state.search.id = search.id
    }

    if ('queries' in search) {
      state.search.history = search.queries
    }
  },

  ADD_HISTORY: (state, query) => {
    if (typeof state.search.history.find(q => q.query === query.query) === 'undefined') {
      state.search.history.unshift(query)
    }

    if (state.search.history.length > 12) {
      state.search.history.pop()
    }
  },

  SET_QUERIES: (state, queries) => {
    state.search.queries = queries
  },

  SAVE_SEARCH_ID_TO_LOCALSTORAGE: (state) => {
    window.localStorage.setItem('search', JSON.stringify({
      id: state.search.id
    }))
  }
}

export const actions = {
  setSearch ({ commit }, search) {
    commit('SET_SEARCH', search)
  },

  addHistory ({ commit }, query) {
    commit('ADD_HISTORY', query)
  },

  setQueries ({ commit }, queries) {
    commit('SET_QUERIES', queries)
  },

  async syncSearch ({ commit, state, rootState }) {
    const country = rootState.country

    if (state.search.id === null) {
      const search = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/search/queries`, state.search, { progress: false })
        .catch((error) => {
          console.log(error)
        })

      commit('SET_SEARCH', search.data)
    } else {
      try {
        const search = await this.$axios.$get(`${this.$i18n.locale}/${country.iso_code}/search/queries/${state.search.id}`, { progress: false })
          .catch((error) => {
            console.log(error)
          })

        commit('SET_SEARCH', search.data)
      } catch (e) {
        const search = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/search/queries`, state.search, { progress: false })
          .catch((error) => {
            console.log(error)
          })

        commit('SET_SEARCH', search.data)
      }
    }

    commit('SAVE_SEARCH_ID_TO_LOCALSTORAGE')

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }
  }
}
