export const state = () => ({
  index: {
    tab: 0,
    topics: []
  }
})

export const getters = {
  index: state => state.index
}

export const mutations = {
  SET_INDEX_TAB: (state, tab) => {
    state.index.tab = tab
  },

  SET_INDEX_TOPICS: (state, topics) => {
    state.index.topics = topics
  }
}

export const actions = {
  setIndexTab ({ commit }, tab) {
    commit('SET_INDEX_TAB', tab)
  },

  setIndexTopics ({ commit }, topics) {
    commit('SET_INDEX_TOPICS', topics)
  }
}
