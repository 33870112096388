export const state = () => ({
  from: ''
})

export const getters = {
  from: state => state.from
}

export const mutations = {
  SET_FROM: (state, from) => { state.from = from }
}

export const actions = {
  setFrom ({ commit }, from) {
    commit('SET_FROM', from)
  }
}
