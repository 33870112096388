export const state = () => ({
  tradein: {
    id: null
  },
  categories: [],
  items: []
})

export const getters = {
  tradein: state => state.tradein,
  categories: state => state.categories,
  items: state => state.items
}

export const mutations = {
  SET_CATEGORIES (state, payload) {
    state.categories = payload
  },

  ADD_ITEM (state, payload) {
    state.items.push(payload)
  },

  REMOVE_ITEM (state, payload) {
    state.items.splice(payload, 1)
  },

  SET_CATEGORY (state, payload) {
    state.items[payload.index].category = payload.category
  },

  SET_OPTIONS (state, payload) {
    state.items[payload.index].options = payload.options
  },

  SET_CHARACTERISTIC_VALUE (state, payload) {
    state.items[payload.index].characteristic.value = payload.characteristicValue
  },

  SET_CHARACTERISTIC_VALUES (state, payload) {
    state.items[payload.index].characteristic.values = payload.characteristicValues
  },

  SET_CHARACTERISTIC (state, payload) {
    state.items[payload.index].characteristic.id = payload.characteristic.id
    state.items[payload.index].characteristic.title = payload.characteristic.title
    state.items[payload.index].characteristic.values = payload.characteristic.values
  },

  SET_PRODUCT (state, payload) {
    state.items[payload.index].product = payload.product
  },

  SET_PRODUCTS (state, payload) {
    state.items[payload.index].products = payload.products
  },

  SET_OPTION_VARIANT (state, payload) {
    state.items[payload.index].options[payload.optionIndex].value = payload.variant
  },

  SET_TRADEIN (state, payload) {
    state.tradein = payload
  }
}

export const actions = {
  setCategories ({ commit }, payload) {
    commit('SET_CATEGORIES', payload)
  },

  addItem ({ commit }, payload) {
    commit('ADD_ITEM', payload)
  },

  removeItem ({ commit }, payload) {
    commit('REMOVE_ITEM', payload)
  },

  setCategory ({ commit }, payload) {
    commit('SET_CATEGORY', payload)
  },

  setOptions ({ commit }, payload) {
    commit('SET_OPTIONS', payload)
  },

  setCharacteristicValue ({ commit }, payload) {
    commit('SET_CHARACTERISTIC_VALUE', payload)
  },

  setCharacteristic ({ commit }, payload) {
    commit('SET_CHARACTERISTIC', payload)
  },

  setProduct ({ commit }, payload) {
    commit('SET_PRODUCT', payload)
  },

  setProducts ({ commit }, payload) {
    commit('SET_PRODUCTS', payload)
  },

  setOptionVariant ({ commit }, payload) {
    commit('SET_OPTION_VARIANT', payload)
  },

  setTradein ({ commit }, payload) {
    commit('SET_TRADEIN', payload)
  }
}
