export const state = () => ({
  chat: {
    id: null,
    key: '',
    user: null,
    department: {
      value: null
    },
    name: {
      value: '',
      error: ''
    },
    email: {
      value: '',
      error: ''
    },
    subject: {
      value: '',
      error: ''
    },
    body: {
      value: '',
      error: ''
    },
    message: {
      value: '',
      sending: false
    },
    messages: [],
    start: {
      loading: false
    },
    active: false
  }
})

export const getters = {
  chat: state => state.chat
}

export const mutations = {
  SET_ID (state, id) {
    state.chat.id = id
  },

  SET_KEY (state, key) {
    state.chat.key = key
  },

  SET_USER (state, user) {
    state.chat.user = user
  },

  SET_DEPARTMENT (state, department) {
    state.chat.department.value = department
  },

  VALIDATE_NAME (state, payload) {
    state.chat.name.error = payload
  },

  SET_NAME (state, name) {
    state.chat.name.value = name
  },

  VALIDATE_EMAIL (state, payload) {
    state.chat.email.error = payload
  },

  SET_EMAIL (state, email) {
    state.chat.email.value = email
  },

  VALIDATE_SUBJECT (state, payload) {
    state.chat.subject.error = payload
  },

  SET_SUBJECT (state, subject) {
    state.chat.subject.value = subject
  },

  SET_BODY (state, body) {
    state.chat.body.value = body
  },

  SET_MESSAGE (state, message) {
    state.chat.message.value = message
  },

  SET_MESSAGES (state, messages) {
    state.chat.messages = messages
  },

  PUSH_MESSAGE (state, message) {
    state.chat.messages.push(message)
  },

  SET_ACTIVE (state, active) {
    state.chat.active = active
  },

  SET_START_LOADING (state, loading) {
    state.chat.start.loading = loading
  },

  SET_MESSAGE_SENDING (state, sending) {
    state.chat.message.sending = sending
  }
}

export const actions = {
  setChat ({ commit }, chat) {
    const localStorageChat = JSON.parse(localStorage.getItem('chat'))

    if (typeof localStorageChat === 'object' && localStorageChat !== null && 'key' in localStorageChat) {
      commit('SET_KEY', localStorageChat.key)
    }

    commit('SET_ID', chat.id)
    commit('SET_USER', chat.user)
    commit('SET_DEPARTMENT', chat.department)
    commit('SET_NAME', chat.name)
    commit('SET_EMAIL', chat.email)
    commit('SET_SUBJECT', chat.subject)
    commit('SET_BODY', chat.body)
    commit('SET_MESSAGES', chat.messages)
  },

  endChat ({ commit, rootState }, chat) {
    commit('SET_KEY', '')
    commit('SET_ID', null)
    commit('SET_USER', null)
    commit('SET_DEPARTMENT', rootState.support.support.chat.departments[0])
    commit('SET_NAME', '')
    commit('SET_EMAIL', '')
    commit('SET_SUBJECT', '')
    commit('SET_BODY', '')
    commit('SET_MESSAGES', [])
  },

  setId ({ commit }, id) {
    commit('SET_ID', id)
  },

  setUser ({ commit }, user) {
    commit('SET_USER', user)
  },

  setDepartment ({ commit }, department) {
    commit('SET_DEPARTMENT', department)
  },

  validateName ({ commit }, payload) {
    commit('VALIDATE_NAME', payload)
  },

  setName ({ commit }, name) {
    commit('SET_NAME', name)
  },

  validateEmail ({ commit }, payload) {
    commit('VALIDATE_EMAIL', payload)
  },

  setEmail ({ commit }, email) {
    commit('SET_EMAIL', email)
  },

  validateSubject ({ commit }, subject) {
    commit('VALIDATE_SUBJECT', subject)
  },

  setSubject ({ commit }, subject) {
    commit('SET_SUBJECT', subject)
  },

  setBody ({ commit }, body) {
    commit('SET_BODY', body)
  },

  setMessage ({ commit }, message) {
    commit('SET_MESSAGE', message)
  },

  setMessages ({ commit }, messages) {
    commit('SET_MESSAGES', messages)
  },

  pushMessage ({ commit }, message) {
    commit('PUSH_MESSAGE', message)
  },

  setActive ({ commit }, active) {
    commit('SET_ACTIVE', active)
  },

  setStartLoading ({ commit }, loading) {
    commit('SET_START_LOADING', loading)
  },

  setMessageSending ({ commit }, sending) {
    commit('SET_MESSAGE_SENDING', sending)
  }
}
