export const state = () => ({
  cart: {
    id: null,
    loading: true,
    items: [],
    creditProductsLoading: true,
    itemRemoving: false,
    creditProducts: null,
    creditProduct: {},
    term: {
      payments: 1
    },
    type: 'cart',
    installment: {
      initial: 0
    }
  },
  modal: {
    active: false
  }
})

export const getters = {
  cart: state => state.cart,
  modal: state => state.modal
}

export const mutations = {
  SET_CART_MODAL_ACTIVE (state, active) {
    state.modal.active = active
  },
  SET_CART: (state, cart) => {
    if ('id' in cart) {
      state.cart.id = cart.id
    }

    if ('type' in cart) {
      state.cart.type = cart.type
    }

    if ('creditProduct' in cart) {
      state.cart.creditProduct = cart.creditProduct
    }

    if ('term' in cart) {
      state.cart.term = cart.term
    }

    if ('items' in cart) {
      state.cart.items = cart.items
    }
  },
  SET_CART_LOADING: (state, loading) => { state.cart.loading = loading },
  SET_CART_ITEM_REMOVING: (state, loading) => { state.cart.itemRemoving = loading },
  SET_CART_CREDIT_PRODUCTS_LOADING: (state, loading) => { state.cart.creditProductsLoading = loading },
  SET_CART_TYPE: (state, type) => { state.cart.type = type },
  SET_CART_CREDIT_PRODUCTS: (state, creditProducts) => {
    state.cart.creditProducts = creditProducts
  },
  SET_CART_CREDIT_PRODUCT: (state, creditProduct) => {
    if (JSON.stringify(creditProduct) === '{}') {
      state.cart.creditProduct = {}

      return
    } else if (typeof creditProduct === 'undefined') {
      return
    }

    let term

    if ('term' in state.cart &&
      Object.keys(state.cart.term).length !== 0 &&
      creditProduct.terms
    ) {
      term = creditProduct.terms.find(term => term.payments === state.cart.term.payments)

      if (typeof term === 'undefined') {
        const x = state.cart.term.payments
        const creditTerms = JSON.parse(JSON.stringify(creditProduct.terms))

        term = creditTerms.sort((a, b) => Math.abs(x - a.payments) - Math.abs(x - b.payments))[0]
      }

      state.cart.term = term || creditProduct.terms[creditProduct.terms.length - 1]
    }

    state.cart.creditProduct = creditProduct

    state.cart.creditProductsLoading = false
  },
  SET_CART_TERM: (state, term) => { state.cart.term = term },
  SET_INITIAL_INSTALLMENT: (state, initialInstallment) => { state.cart.installment.initial = initialInstallment },
  ADD_TO_CART: (state, { item, itemStore, warranties, addons, currency, cheaperTogetherWith }) => {
    if (cheaperTogetherWith !== null) {
      cheaperTogetherWith = state.cart.items.find(
        cartItem => cartItem.cartable_id === cheaperTogetherWith.itemStore.id &&
          cartItem.class === cheaperTogetherWith.itemStore.class &&
          // (cartItem.warranty !== null
          //   ? (
          //     warranty !== null
          //       ? cartItem.warranty.id === warranty.id
          //       : false
          //   )
          //   : (warranty === [])
          // ) &&
          cartItem.warranties.length === cheaperTogetherWith.warranties.length &&
          (cheaperTogetherWith.warranties.length > 0
            ? cartItem.warranties.every(c => cheaperTogetherWith.warranties.find(e => e.id === c.id))
            : true) &&
          cartItem.addons.length === cheaperTogetherWith.addons.length &&
          (cheaperTogetherWith.addons.length > 0
            ? cartItem.addons.every(c => cheaperTogetherWith.addons.find(e => e.id === c.id))
            : true)
      )
    }

    const found = state.cart.items.find(
      cartItem => cartItem.cartable_id === itemStore.id &&
        cartItem.class === itemStore.class &&
        // (cartItem.warranty !== null
        //   ? (
        //     warranty !== null
        //       ? cartItem.warranty.id === warranty.id
        //       : false
        //   )
        //   : (warranty === [])
        // ) &&
        cartItem.warranties.length === warranties.length &&
        (warranties.length > 0
          ? cartItem.warranties.every(c => warranties.find(e => e.id === c.id))
          : true) &&
        cartItem.addons.length === addons.length &&
        (addons.length > 0
          ? cartItem.addons.every(c => addons.find(e => e.id === c.id))
          : true) &&
        (
          (cheaperTogetherWith === null && cartItem.cheaperTogetherWith === null) ||
          (cheaperTogetherWith && cartItem.cheaperTogetherWith && cartItem.cheaperTogetherWith.id === cheaperTogetherWith.id)
        )
    )

    if (found) {
      found.quantity++
      let price = (found.store.price.current + (warranties.reduce((total, item) => total + item.price, 0) || 0) + (addons.reduce((total, item) => total + item.price, 0) || 0))

      if (found.coupon && this.item.coupon.discount.type.type === 'percent') {
        price = price - (price * this.item.coupon.discount.percent / 100)
      } else if (found.coupon && this.item.coupon.discount.type.type === 'amount') {
        price = price - this.item.coupon.discount.amount.amount
      }

      found.total = found.quantity * price
    } else {
      state.cart.items.unshift({
        cartable_id: itemStore.id,
        image: {
          id: item.images[0].id,
          alt: item.images[0].alt,
          folder: item.images[0].folder,
          url: item.images[0].url
        },
        title: item.title,
        class: itemStore.class,
        // store_id: itemStore.id,
        quantity: 1,
        warranties,
        addons,
        store: itemStore,
        currency,
        cheaperTogetherWith,
        brand: item.brand,
        breadcrumbs: item.breadcrumbs,
        page: item.page,
        price: itemStore.price.current,
        total: itemStore.price.current + (warranties.reduce((total, item) => total + item.price, 0) || 0) + (addons.reduce((total, item) => total + item.price, 0) || 0),
        coupon: null
      })
    }
  },
  SUBTRACT_QUANTITY: (state, item) => {
    const found = state.cart.items.find(cartItem => cartItem === item)

    if (found.quantity > 1) {
      found.quantity--
    }

    state.cart.items.filter(i => i.cheaperTogetherWith && i.cheaperTogetherWith.id === item.id).forEach((cheaperTogetherItem) => {
      if (cheaperTogetherItem.quantity > item.quantity) {
        cheaperTogetherItem.quantity = item.quantity
      }
    })
  },
  ADD_QUANTITY: (state, item) => {
    const found = state.cart.items.find(cartItem => cartItem === item)

    found.quantity++
  },
  REMOVE_FROM_CART: (state, item) => {
    const itemsToRemove = [item]

    const cheaperTogetherItemsToRemove = state.cart.items.filter(i => i.cheaperTogetherWith && i.cheaperTogetherWith.id === item.id)

    if (cheaperTogetherItemsToRemove.length) {
      itemsToRemove.push(...cheaperTogetherItemsToRemove)
    }

    itemsToRemove.forEach((item) => {
      const index = state.cart.items.indexOf(item)

      if (index > -1) {
        state.cart.items.splice(index, 1)
      }
    })
  },
  SAVE_CART_ID_TO_LOCALSTORAGE: (state) => {
    window.localStorage.setItem('cart', JSON.stringify({
      id: state.cart.id
    }))
  },
  APPLY_CART_ITEM_COUPON: (state, item) => {
    state.cart.items.find(i => i.id === item.id).coupon = item.coupon
  },
  REMOVE_CART_ITEM_COUPON: (state, item) => {
    state.cart.items.find(i => i.id === item.id).coupon = null
  }
}

export const actions = {
  setCartModalActive ({ commit }, active) {
    commit('SET_CART_MODAL_ACTIVE', active)
  },
  setCart ({ commit }, cart) {
    commit('SET_CART', cart)
  },
  setCartLoading ({ commit }, loading) {
    commit('SET_CART_LOADING', loading)
  },
  setCartType ({ commit }, type) {
    commit('SET_CART_TYPE', type)
  },
  setCartCreditProductsLoading ({ commit }, creditProductsLoading) {
    commit('SET_CART_CREDIT_PRODUCTS_LOADING', creditProductsLoading)
  },
  setCartCreditProducts ({ commit, state, rootState }) {
    commit('SET_CART_CREDIT_PRODUCTS_LOADING', true)

    this.$axios.$get(`${this.$i18n.locale}/${rootState.country.iso_code}/cart/${state.cart.id}/payment/companies/products`)
      .then((res) => {
        commit('SET_CART_CREDIT_PRODUCTS', res.data)

        if (state.cart.creditProduct !== undefined &&
          Object.keys(state.cart.creditProduct).length !== 0 &&
          state.cart.creditProduct.constructor === Object &&
          (state.cart.creditProducts !== null && state.cart.creditProducts.length)
        ) {
          commit('SET_CART_CREDIT_PRODUCT', state.cart.creditProduct)
        } else if (state.cart.creditProducts !== null &&
          state.cart.creditProducts.length > 0
        ) {
          commit('SET_CART_CREDIT_PRODUCT', state.cart.creditProducts[0])
        } else if (state.cart.creditProduct !== undefined &&
          Object.keys(state.cart.creditProduct).length !== 0 &&
          state.cart.creditProduct.constructor === Object &&
          (state.cart.creditProducts === null || state.cart.creditProducts.length === 0)
        ) {
          commit('SET_CART_TERM', {
            payments: 1
          })
          commit('SET_CART_CREDIT_PRODUCT', {})

          this.dispatch('checkout/setPaymentTab', 'cart')
          this.dispatch('cart/setCartType', 'cart')

          const selectedDeliveryMethod = rootState.checkout.checkout.form.delivery.methods.items.find(item => item.id === rootState.checkout.checkout.form.delivery.method)

          if (selectedDeliveryMethod &&
            rootState.checkout.checkout.form.payment.method &&
            selectedDeliveryMethod.paymentMethods.filter(item => item.tab === 'cart').length > 0
          ) {
            this.dispatch('checkout/setPaymentMethod', selectedDeliveryMethod.paymentMethods.filter(item => item.tab === 'cart')[0].id)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        commit('SET_CART_CREDIT_PRODUCTS_LOADING', false)
      })
  },
  setCreditProducts ({ commit, state, rootState }, creditProducts) {
    commit('SET_CART_CREDIT_PRODUCTS', creditProducts)

    if (state.cart.creditProduct !== undefined &&
      Object.keys(state.cart.creditProduct).length !== 0 &&
      state.cart.creditProduct.constructor === Object &&
      (state.cart.creditProducts !== null && state.cart.creditProducts.length)
    ) {
      commit('SET_CART_CREDIT_PRODUCT', state.cart.creditProduct)
    } else if (state.cart.creditProducts !== null &&
      state.cart.creditProducts.length > 0
    ) {
      commit('SET_CART_CREDIT_PRODUCT', state.cart.creditProducts[0])
    } else if (state.cart.creditProduct !== undefined &&
      Object.keys(state.cart.creditProduct).length !== 0 &&
      state.cart.creditProduct.constructor === Object &&
      (state.cart.creditProducts === null || state.cart.creditProducts.length === 0)
    ) {
      commit('SET_CART_TERM', {
        payments: 1
      })
      commit('SET_CART_CREDIT_PRODUCT', {})

      this.dispatch('checkout/setPaymentTab', 'cart')
      this.dispatch('cart/setCartType', 'cart')

      const selectedDeliveryMethod = rootState.checkout.checkout.form.delivery.methods.items.find(item => item.id === rootState.checkout.checkout.form.delivery.method)

      if (selectedDeliveryMethod &&
        rootState.checkout.checkout.form.payment.method &&
        selectedDeliveryMethod.paymentMethods.filter(item => item.tab === 'cart').length > 0
      ) {
        this.dispatch('checkout/setPaymentMethod', selectedDeliveryMethod.paymentMethods.filter(item => item.tab === 'cart')[0].id)
      }
    }
  },
  async setCartCreditProduct ({ commit }, creditProduct) {
    await commit('SET_CART_CREDIT_PRODUCT', creditProduct)
  },
  setCartTerm ({ commit }, term) {
    commit('SET_CART_TERM', term)
  },
  setInitialInstallment ({ commit }, initialInstallment) {
    commit('SET_INITIAL_INSTALLMENT', initialInstallment)
  },
  async addToCart ({ commit, state, rootState }, { item, itemStore, warranties, addons, cheaperTogetherWith = null }) {
    commit('SET_CART_LOADING', true)
    commit('SET_CART_CREDIT_PRODUCTS_LOADING', true)

    const currency = rootState.currency
    const country = rootState.country

    warranties = warranties.filter(warranty => typeof warranty === 'object' && warranty !== null)
    addons = addons.filter(addon => typeof addon === 'object' && addon !== null)

    commit('ADD_TO_CART', { item, itemStore, warranties, addons, currency, cheaperTogetherWith })

    const { id, items, term, type } = state.cart
    const cart = { id, items, term, type }

    if (state.cart.id === null) {
      const response = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/cart`, cart)
        .catch((error) => {
          console.log(error)
        })

      commit('SET_CART', response.data)
      commit('SAVE_CART_ID_TO_LOCALSTORAGE')
    } else {
      const response = await this.$axios.$put(`${this.$i18n.locale}/${country.iso_code}/cart/${cart.id}`, cart)
        .catch((error) => {
          console.log(error)
        })

      commit('SET_CART', response.data)
    }

    const found = state.cart.items.find(
      cartItem => cartItem.cartable_id === itemStore.id &&
        cartItem.class === itemStore.class &&
        // (cartItem.warranty !== null
        //   ? (
        //     warranty !== null
        //       ? cartItem.warranty.id === warranty.id
        //       : false
        //   )
        //   : (warranty === [])
        // ) &&
        cartItem.warranties.length === warranties.length &&
        (warranties.length > 0
          ? cartItem.warranties.every(c => warranties.find(e => e.id === c.id))
          : true) &&
        cartItem.addons.length === addons.length &&
        (addons.length > 0
          ? cartItem.addons.every(c => addons.find(e => e.id === c.id))
          : true)
    )

    this.dispatch('cart/sendGtmEvent', { item: found, event: 'add_to_cart' })

    await this.dispatch('cart/setCartCreditProducts')

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }

    commit('SET_CART_LOADING', false)
  },
  async subtractQuantity ({ commit, state, rootState }, item) {
    commit('SET_CART_LOADING', true)

    commit('SUBTRACT_QUANTITY', item)

    await this.dispatch('cart/changeQuantity', item)

    this.dispatch('cart/sendGtmEvent', { item, event: 'remove_from_cart' })

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }

    commit('SET_CART_LOADING', false)
  },
  async subtractCheckoutQuantity ({ commit, state, rootState }, item) {
    commit('SUBTRACT_QUANTITY', item)

    await this.dispatch('cart/changeQuantity', item)

    this.dispatch('cart/sendGtmEvent', { item, event: 'remove_from_cart' })

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }
  },
  async addQuantity ({ commit, state, rootState }, item) {
    commit('SET_CART_LOADING', true)

    commit('ADD_QUANTITY', item)

    await this.dispatch('cart/changeQuantity', item)

    this.dispatch('cart/sendGtmEvent', { item, event: 'add_to_cart' })

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }

    commit('SET_CART_LOADING', false)
  },
  async addCheckoutQuantity ({ commit, state, rootState }, item) {
    commit('ADD_QUANTITY', item)

    await this.dispatch('cart/changeQuantity', item)

    this.dispatch('cart/sendGtmEvent', { item, event: 'add_to_cart' })

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }
  },
  async changeQuantity ({ commit, state, rootState }, item) {
    commit('SET_CART_CREDIT_PRODUCTS_LOADING', true)

    const found = state.cart.items.find(cartItem => cartItem === item)

    const country = rootState.country

    await this.$axios.$put(`${this.$i18n.locale}/${country.iso_code}/cart/${state.cart.id}/items/${found.id}`, found)
      .catch((error) => {
        console.log(error)
      })

    await this.dispatch('cart/setCartCreditProducts')

    if (Object.keys(state.cart.creditProduct).length > 0 &&
      !(
        typeof state.cart.creditProducts.find(product => product.id === state.cart.creditProduct.id) !== 'undefined' &&
        typeof state.cart.creditProducts.find(product => product.id === state.cart.creditProduct.id).terms.find(term => term.payments === state.cart.term.payments) !== 'undefined'
      )
    ) {
      await this.dispatch('cart/setCartCreditProduct', {})
    }
  },
  async removeFromCart ({ commit, state, rootState }, item) {
    commit('SET_CART_LOADING', true)
    commit('SET_CART_ITEM_REMOVING', true)

    const country = rootState.country

    await this.$axios.$delete(`${this.$i18n.locale}/${country.iso_code}/cart/${state.cart.id}/items/${item.id}`)
      .catch((error) => {
        console.log(error)
      })

    commit('REMOVE_FROM_CART', item)

    item.quantity = 0

    this.dispatch('cart/sendGtmEvent', { item, event: 'remove_from_cart' })

    this.dispatch('cart/setCartCreditProducts')

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }

    commit('SET_CART_ITEM_REMOVING', false)
    commit('SET_CART_LOADING', false)
  },
  async syncCart ({ commit, state, rootState }, loading = true) {
    commit('SET_CART_LOADING', loading)

    const country = rootState.country

    const { id, items, term, type } = state.cart
    const cart = { id, items, term, type }

    if (state.cart.id === null) {
      const response = await this.$axios.$post(`${this.$i18n.locale}/${country.iso_code}/cart`, cart)

      commit('SET_CART', response.data)
    } else {
      const response = await this.$axios.$put(`${this.$i18n.locale}/${country.iso_code}/cart/${cart.id}`, state.cart)
        .catch((error) => {
          console.log(error)
        })

      commit('SET_CART', response.data)
    }

    commit('SAVE_CART_ID_TO_LOCALSTORAGE')

    // if (rootState.auth.loggedIn) {
    //   rootState.auth.fetchUser()
    // }

    commit('SET_CART_LOADING', false)
  },
  addCouponToCartItem ({ commit, state, rootState }, item) {
    commit('APPLY_CART_ITEM_COUPON', item)
  },
  removeCouponFromCartItem ({ commit, state, rootState }, item) {
    commit('REMOVE_CART_ITEM_COUPON', item)
  },
  sendGtmEvent ({ commit, state, rootState }, payload) {
    this.$gtm.push({
      event: payload.event,
      ecommerce: {
        items: [
          {
            item_id: payload.item.cartable_id,
            item_name: payload.item.title,
            price: payload.item.price,
            item_brand: payload.item.brand,
            item_category: payload.item.breadcrumbs.length ? payload.item.breadcrumbs[0].title : '',
            item_category2: payload.item.breadcrumbs.length ? payload.item.breadcrumbs[payload.item.breadcrumbs.length - 1].title : '',
            quantity: payload.item.quantity
          }
        ]
      }
    })
  }
}
