export const state = () => ({
  ticket: {
    id: null,
    user: null,
    department: {
      value: null
    },
    name: {
      value: '',
      error: ''
    },
    email: {
      value: '',
      error: ''
    },
    subject: {
      value: '',
      error: ''
    },
    body: {
      value: '',
      error: ''
    },
    submit: {
      loading: false
    },
    active: false
  }
})

export const getters = {
  ticket: state => state.ticket
}

export const mutations = {
  SET_ID (state, id) {
    state.ticket.id = id
  },

  SET_USER (state, user) {
    state.ticket.user = user
  },

  SET_DEPARTMENT (state, department) {
    state.ticket.department.value = department
  },

  VALIDATE_NAME (state, payload) {
    state.ticket.name.error = payload
  },

  SET_NAME (state, name) {
    state.ticket.name.value = name
  },

  VALIDATE_EMAIL (state, payload) {
    state.ticket.email.error = payload
  },

  SET_EMAIL (state, email) {
    state.ticket.email.value = email
  },

  VALIDATE_SUBJECT (state, payload) {
    state.ticket.subject.error = payload
  },

  SET_SUBJECT (state, subject) {
    state.ticket.subject.value = subject
  },

  SET_BODY (state, body) {
    state.ticket.body.value = body
  },

  SET_ACTIVE (state, active) {
    state.ticket.active = active
  },

  SET_SUBMIT_LOADING (state, loading) {
    state.ticket.submit.loading = loading
  }
}

export const actions = {
  setTicket ({ commit }, ticket) {
    commit('SET_ID', ticket.id)
    commit('SET_USER', ticket.user)
    commit('SET_DEPARTMENT', ticket.department)
    commit('SET_NAME', ticket.name)
    commit('SET_EMAIL', ticket.email)
    commit('SET_SUBJECT', ticket.subject)
    commit('SET_BODY', ticket.body)
  },

  setId ({ commit }, id) {
    commit('SET_ID', id)
  },

  setUser ({ commit }, user) {
    commit('SET_USER', user)
  },

  setDepartment ({ commit }, department) {
    commit('SET_DEPARTMENT', department)
  },

  validateName ({ commit }, payload) {
    commit('VALIDATE_NAME', payload)
  },

  setName ({ commit }, name) {
    commit('SET_NAME', name)
  },

  validateEmail ({ commit }, payload) {
    commit('VALIDATE_EMAIL', payload)
  },

  setEmail ({ commit }, email) {
    commit('SET_EMAIL', email)
  },

  validateSubject ({ commit }, subject) {
    commit('VALIDATE_SUBJECT', subject)
  },

  setSubject ({ commit }, subject) {
    commit('SET_SUBJECT', subject)
  },

  setBody ({ commit }, body) {
    commit('SET_BODY', body)
  },

  setActive ({ commit }, active) {
    commit('SET_ACTIVE', active)
  },

  setSubmitLoading ({ commit }, loading) {
    commit('SET_SUBMIT_LOADING', loading)
  }
}
