export const state = () => ({
  popupAd: {
    closed: false
  },
  info: {
    title: '',
    description: '',
    page: {
      slug: ''
    },
    active: false
  },
  preorder: {
    user: {
      name: {
        value: '',
        error: ''
      },
      lastname: {
        value: '',
        error: ''
      },
      patronymic: {
        value: '',
        error: ''
      },
      phone: {
        model: '',
        number: '',
        error: ''
      },
      email: {
        value: '',
        error: ''
      }
    },
    product: {
      id: null,
      title: '',
      image: {
        lazy: '',
        src: '',
        alt: ''
      }
    },
    note: {
      body: ''
    },
    active: false
  },
  notifyAboutProductAvailability: {
    user: {
      name: {
        value: '',
        error: ''
      },
      lastname: {
        value: '',
        error: ''
      },
      patronymic: {
        value: '',
        error: ''
      },
      phone: {
        model: '',
        number: '',
        error: ''
      },
      email: {
        value: '',
        error: ''
      }
    },
    product: {
      id: null,
      title: '',
      image: {
        lazy: '',
        src: '',
        alt: ''
      }
    },
    note: {
      body: ''
    },
    callback: false,
    active: false
  },
  callback: {
    active: false
  },
  email: {
    tab: null,
    tabs: [

    ],
    active: false
  }
})

export const getters = {
  popupAd: state => state.popupAd,
  info: state => state.info,
  preorder: state => state.preorder,
  notifyAboutProductAvailability: state => state.notifyAboutProductAvailability,
  callback: state => state.callback,
  email: state => state.email
}

export const mutations = {
  SET_POPUP_AD_CLOSED: (state, closed) => { state.popupAd.closed = closed },

  // Info
  SET_INFO_TITLE: (state, title) => { state.info.title = title },
  SET_INFO_DESCRIPTION: (state, description) => { state.info.description = description },
  SET_INFO_PAGE: (state, page) => { state.info.page = page },
  SET_INFO_ACTIVE: (state, active) => { state.info.active = active },

  // Pre-order
  SET_PREORDER_ID: (state, id) => { state.preorder.product.id = id },
  SET_PREORDER_TITLE: (state, title) => { state.preorder.product.title = title },
  SET_PREORDER_IMAGE: (state, image) => { state.preorder.product.image = image },
  TOGGLE_PREORDER_MODAL: (state, active) => {
    state.preorder.active = active
    state.preorder.user.phone.error = ''
    state.preorder.user.name.error = ''
    state.preorder.user.email.error = ''
  },
  SET_PREORDER_USER_NAME (state, payload) { state.preorder.user.name.value = payload },
  SET_PREORDER_USER_LASTNAME (state, payload) { state.preorder.user.lastname.value = payload },
  SET_PREORDER_USER_PATRONYMIC (state, payload) { state.preorder.user.patronymic.value = payload },
  SET_PREORDER_USER_PHONE (state, payload) { state.preorder.user.phone.number = payload },
  SET_PREORDER_USER_PHONE_MODEL (state, payload) { state.preorder.user.phone.model = payload },
  SET_PREORDER_USER_EMAIL_VALUE (state, payload) { state.preorder.user.email.value = payload },
  SET_PREORDER_USER_PHONE_ERROR (state, payload) { state.preorder.user.phone.error = payload },
  VALIDATE_PREORDER_USER_NAME (state, payload) { state.preorder.user.name.error = payload },
  VALIDATE_PREORDER_USER_EMAIL (state, payload) { state.preorder.user.email.error = payload },
  SET_PREORDER_NOTE_BODY (state, payload) { state.preorder.note.body = payload },

  // Notify About Product Availability
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_ID: (state, id) => { state.notifyAboutProductAvailability.product.id = id },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_TITLE: (state, title) => { state.notifyAboutProductAvailability.product.title = title },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_IMAGE: (state, image) => { state.notifyAboutProductAvailability.product.image = image },
  TOGGLE_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_MODAL: (state, active) => {
    state.notifyAboutProductAvailability.active = active
    state.notifyAboutProductAvailability.user.phone.error = ''
    state.notifyAboutProductAvailability.user.name.error = ''
    state.notifyAboutProductAvailability.user.email.error = ''
  },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_NAME (state, payload) { state.notifyAboutProductAvailability.user.name.value = payload },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_LASTNAME (state, payload) { state.notifyAboutProductAvailability.user.lastname.value = payload },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_PATRONYMIC (state, payload) { state.notifyAboutProductAvailability.user.patronymic.value = payload },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_PHONE (state, payload) { state.notifyAboutProductAvailability.user.phone.number = payload },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_PHONE_MODEL (state, payload) { state.notifyAboutProductAvailability.user.phone.model = payload },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_EMAIL_VALUE (state, payload) { state.notifyAboutProductAvailability.user.email.value = payload },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_PHONE_ERROR (state, payload) { state.notifyAboutProductAvailability.user.phone.error = payload },
  VALIDATE_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_NAME (state, payload) { state.notifyAboutProductAvailability.user.name.error = payload },
  VALIDATE_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_EMAIL (state, payload) { state.notifyAboutProductAvailability.user.email.error = payload },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_NOTE_BODY (state, payload) { state.notifyAboutProductAvailability.note.body = payload },
  SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_CALLBACK (state, payload) { state.notifyAboutProductAvailability.callback = payload },
  SET_CALLBACK_ACTIVE (state, payload) { state.callback.active = payload },
  SET_EMAIL_TAB (state, payload) { state.email.tab = payload },
  SET_EMAIL_TABS (state, payload) { state.email.tabs = payload },
  SET_EMAIL_ACTIVE (state, payload) { state.email.active = payload }
}

export const actions = {
  closePopupAd ({ commit }, closed) {
    commit('SET_POPUP_AD_CLOSED', closed)
  },

  // Info
  setInfoModalTitle ({ commit }, title) {
    commit('SET_INFO_TITLE', title)
  },

  setInfoModalDescription ({ commit }, description) {
    commit('SET_INFO_DESCRIPTION', description)
  },

  setInfoModalPage ({ commit }, page) {
    commit('SET_INFO_PAGE', page)
  },

  setInfoModalActive ({ commit }, active) {
    commit('SET_INFO_ACTIVE', active)
  },

  // Pre-order
  setPreorderId ({ commit }, id) {
    commit('SET_PREORDER_ID', id)
  },

  setPreorderTitle ({ commit }, title) {
    commit('SET_PREORDER_TITLE', title)
  },

  setPreorderImage ({ commit }, image) {
    commit('SET_PREORDER_IMAGE', image)
  },

  togglePreorderModal ({ commit }, active) {
    commit('TOGGLE_PREORDER_MODAL', active)
  },

  preorderSetUserNameValue ({ commit }, payload) {
    commit('SET_PREORDER_USER_NAME', payload)
  },

  preorderSetUserLastnameValue ({ commit }, payload) {
    commit('SET_PREORDER_USER_LASTNAME', payload)
  },

  preorderSetUserPatronymicValue ({ commit }, payload) {
    commit('SET_PREORDER_USER_PATRONYMIC', payload)
  },

  preorderSetUserPhone ({ commit }, payload) {
    commit('SET_PREORDER_USER_PHONE', payload)
  },

  preorderSetUserPhoneModel ({ commit }, payload) {
    commit('SET_PREORDER_USER_PHONE_MODEL', payload)
  },

  preorderSetUserEmailValue ({ commit }, payload) {
    commit('SET_PREORDER_USER_EMAIL_VALUE', payload)
  },

  preorderValidateUserName ({ commit }, payload) {
    commit('VALIDATE_PREORDER_USER_NAME', payload)
  },

  preorderValidateUserEmail ({ commit }, payload) {
    commit('VALIDATE_PREORDER_USER_EMAIL', payload)
  },

  preorderSetUserPhoneError ({ commit }, payload) {
    commit('SET_PREORDER_USER_PHONE_ERROR', payload)
  },

  preorderSetNoteBody ({ commit }, payload) {
    commit('SET_PREORDER_NOTE_BODY', payload)
  },

  // Notify About Product Availability
  setNotifyAboutProductAvailabilityId ({ commit }, id) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_ID', id)
  },

  setNotifyAboutProductAvailabilityTitle ({ commit }, title) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_TITLE', title)
  },

  setNotifyAboutProductAvailabilityImage ({ commit }, image) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_IMAGE', image)
  },

  toggleNotifyAboutProductAvailabilityModal ({ commit }, active) {
    commit('TOGGLE_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_MODAL', active)
  },

  notifyAboutProductAvailabilitySetUserNameValue ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_NAME', payload)
  },

  notifyAboutProductAvailabilitySetUserLastnameValue ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_LASTNAME', payload)
  },

  notifyAboutProductAvailabilitySetUserPatronymicValue ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_PATRONYMIC', payload)
  },

  notifyAboutProductAvailabilitySetUserPhone ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_PHONE', payload)
  },

  notifyAboutProductAvailabilitySetUserPhoneModel ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_PHONE_MODEL', payload)
  },

  notifyAboutProductAvailabilitySetUserEmailValue ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_EMAIL_VALUE', payload)
  },

  notifyAboutProductAvailabilityValidateUserName ({ commit }, payload) {
    commit('VALIDATE_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_NAME', payload)
  },

  notifyAboutProductAvailabilityValidateUserEmail ({ commit }, payload) {
    commit('VALIDATE_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_EMAIL', payload)
  },

  notifyAboutProductAvailabilitySetUserPhoneError ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_USER_PHONE_ERROR', payload)
  },

  notifyAboutProductAvailabilitySetNoteBody ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_NOTE_BODY', payload)
  },

  notifyAboutProductAvailabilitySetCallback ({ commit }, payload) {
    commit('SET_NOTIFY_ABOUT_PRODUCT_AVAILABILITY_CALLBACK', payload)
  },

  setCallbackActive ({ commit }, payload) {
    commit('SET_CALLBACK_ACTIVE', payload)
  },

  setEmailTab ({ commit }, payload) {
    commit('SET_EMAIL_TAB', payload)
  },

  setEmailTabs ({ commit }, payload) {
    commit('SET_EMAIL_TABS', payload)
  },

  setEmailActive ({ commit }, payload) {
    commit('SET_EMAIL_ACTIVE', payload)
  }
}
