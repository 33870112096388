export const state = () => ({
  mobile: {
    active: false
  }
})

export const getters = {
  mobile: state => state.mobile
}

export const mutations = {
  SET_MOBILE_MENU_ACTIVE (state, active) {
    state.mobile.active = active
  }
}

export const actions = {
  setMobileMenuActive ({ commit }, active) {
    commit('SET_MOBILE_MENU_ACTIVE', active)
  }
}
