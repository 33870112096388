export const state = () => ({
  wishlist: {
    count: null,
    items: []
  }
})

export const getters = {
  wishlist: state => state.wishlist
}

export const mutations = {
  SET_WISHLIST: (state, wishlist) => { state.wishlist = wishlist },
  ADD_TO_WISHLIST: (state, item) => {
    state.wishlist.items.push(item)
    state.wishlist.count++
  }
}

export const actions = {
  addToWishlist ({ commit }, item) {
    commit('ADD_TO_WISHLIST', item)
  }
}
