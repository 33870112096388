export const state = () => ({
  order: {
    id: null,
    callback: null
  }
})

export const getters = {
  order: state => state.order
}

export const mutations = {
  SET_ORDER: (state, order) => {
    state.order = order
  }
}
export const actions = {
  setOrder ({ commit }, order) {
    commit('SET_ORDER', order)
  }
}
