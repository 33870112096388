export const state = () => ({
  support: {
    active: true,
    productFixedPriceButton: false,
    contacts: [],
    schedule: {},
    chat: {
      departments: []
    },
    ticket: {
      departments: []
    }
  }
})

export const getters = {
  support: state => state.support
}

export const mutations = {
  SET_PRODUCT_FIXED_PRICE_BUTTON: (state, button) => { state.support.productFixedPriceButton = button },
  SET_CONTACTS: (state, contacts) => { state.support.contacts = contacts },
  SET_SCHEDULE: (state, schedule) => { state.support.schedule = schedule },
  SET_CHAT_DEPARTMENTS: (state, departments) => { state.support.chat.departments = departments },
  SET_TICKET_DEPARTMENTS: (state, departments) => { state.support.ticket.departments = departments },
  SET_ACTIVE (state, active) { state.support.active = active }
}

export const actions = {
  setProductFixedPriceButton ({ commit }, productFixedPriceButton) {
    commit('SET_PRODUCT_FIXED_PRICE_BUTTON', productFixedPriceButton)
  },

  setContacts ({ commit }, contacts) {
    commit('SET_CONTACTS', contacts)
  },

  setSchedule ({ commit }, schedule) {
    commit('SET_SCHEDULE', schedule)
  },

  setChatDepartment ({ commit }, departments) {
    commit('SET_CHAT_DEPARTMENTS', departments)
  },

  setTicketDepartment ({ commit }, departments) {
    commit('SET_TICKET_DEPARTMENTS', departments)
  },

  setActive ({ commit }, active) {
    commit('SET_ACTIVE', active)
  }
}
